import { useEffect, useState } from "react";

function Main() {
  const [titles] = useState([
    {
      title: "developer.",
      gradient: "linear-gradient(90deg, #FA8BFF 0%, #2BD2FF 52%, #2BFF88 90%)",
    },
    {
      title: "designer.",
      gradient:
        "linear-gradient(90deg,#4ab1f1,#566cec 25%,#d749af 75%,#ff7c51)",
    },
    {
      title: "creator.",
      gradient:
        "linear-gradient(90deg,#566cec,#d749af 25%,#ff7c51 75%,#f2b14d)",
    },
    {
      title: "dreamer.",
      gradient:
        "linear-gradient(90deg,#ff7c51,#f2b14d 25%,#6bbba6 75%,#4ab1f1)",
    },
  ]);

  const [titleNumber, setTitleNumber] = useState(0);

  useEffect(() => {
    changeTitle();
  }, []);

  const changeTitle = () => {
    let count = -1;
    setInterval(() => {
      if (count >= 3) {
        count = 0;
        setTitleNumber(0);
      } else {
        count++;
        setTitleNumber(count);
      }
    }, 1500);
  };

  return (
    <div
      className="w-full h-screen flex flex-col justify-center items-center"
      id="main"
    >
      <div className="w-full h-44 flex flex-col items-center justify-center">
        <h1 className="text-lg lg:text-2xl">I'm a </h1>
        <div
          className="w-auto h-auto flex justify-center items-center bg-clip-text text-transparent lg:w-auto"
          style={{
            backgroundImage: titles[titleNumber].gradient,
          }}
        >
          <h1 className="h-20 text-5xl font-extrabold lg:h-32 lg:text-8xl">
            {titles[titleNumber].title}
          </h1>
        </div>
      </div>
      <div className="w-full h-auto flex justify-center items-center">
        <p className="w-5/6 text-xl text-center lg:text-4xl lg:leading-normal">
          Hi! I am <span className="font-semibold">Nimalan Sivakumar</span>. I
          design and develop fully functional web applications using JavaScript.
          High <span className="font-semibold">caffeine 🍵</span> drives me
          crazy. I spent most of my time contributing to
          <span className="font-semibold"> open-source</span> and I'm also
          working on personal branding.
        </p>
      </div>
    </div>
  );
}

export default Main;
