import momento from "./assets/momento.svg";
import devconnect from "./assets/devconnect.svg";
import githublink from "./assets/githublink.svg";
import { FaGithub, FaExternalLinkAlt } from "react-icons/fa";
import live from "./assets/external-link.svg";
import { useState } from "react";

function Projects() {
  const [projects] = useState([
    {
      image: momento,
      project_name: "Momento",
      desc: "A productivity app to track the progress of your projects",
      link: "https://momento-app.netlify.app/",
      githublink: "https://github.com/nimalansivakumar/momento",
    },
    {
      image: devconnect,
      project_name: "DevConnect",
      desc: "A developer hub to know the works of aspiring developers",
      link: "https://dev-connect.vercel.app/",
      githublink: "https://github.com/nimalansivakumar/DevConnect",
    },
  ]);
  return (
    <div className="w-full h-auto flex flex-col justify-around items-center" id="projects">
      <div
        className="w-full h-auto mb-10 flex flex-col justify-around items-center bg-clip-text text-transparent"
        style={{
          backgroundImage:
            "linear-gradient(90deg,#4ab1f1,#566cec 25%,#d749af 75%,#ff7c51)",
        }}
      >
        <h1 className="h-20 text-5xl font-extrabold lg:text-7xl">projects.</h1>
      </div>
      <div className="w-full h-auto grid grid-cols-1 justify-items-center lg:w-5/6 lg:grid-cols-3 ">
        {projects.map((project) => (
          <div className="w-80 h-96 mb-10 rounded flex flex-col items-center justify-between cursor-pointer shadow-md transform transition-all hover:-translate-y-2 hover:bg-gray-100 hover:shadow-none">
            <div className="w-11/12 h-auto my-2">
              <img src={project.image} className="w-full h-full" />
            </div>
            <div className="w-full h-auto">
              <h1 className="font-bold text-xl m-2">{project.project_name}</h1>
              <p className="text-lg mx-2">{project.desc}</p>
            </div>
            <div className="w-full h-20 flex items-center">
              <a href={project.githublink} target="_blank">
                <FaGithub className="w-7 h-7 mx-2" />
              </a>
              <a href={project.link} target="_blank">
                <FaExternalLinkAlt className="w-6 h-6 mx-2" />
              </a>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Projects;
