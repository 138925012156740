import { FaGithub, FaLinkedin, FaTwitter } from "react-icons/fa";

const ContactBar = () => {
  return (
    <div className="w-full h-14 flex justify-center items-center lg:hidden">
      <div className="w-5/6 h-full flex flex-row justify-around items-center">
        <a href="https://github.com/nimalansivakumar" target="_blank">
          <FaGithub className="w-7 h-7 cursor-pointer" />
        </a>
        <a href="https://twitter.com/nimalancodes" target="_blank">
          <FaTwitter className="w-7 h-7 cursor-pointer" />
        </a>
        <a
          href="https://www.linkedin.com/in/nimalan-sivakumar-743507203/"
          target="_blank"
        >
          <FaLinkedin className="w-7 h-7 cursor-pointer" />
        </a>
      </div>
    </div>
  );
};

export default ContactBar;
