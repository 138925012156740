import { useState } from "react";
import { FaGithub, FaLinkedin, FaTwitter } from "react-icons/fa";

function Navbar() {
  const [currentNav, setCurrentNav] = useState();
  const handleScroll = (id) => {
    document.getElementById(id).scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
    setCurrentNav(id);
  };
  return (
    <nav className="fixed z-10 bg-white-900 w-full h-28 flex flex-col items-center justify-around bg-clip-padding backdrop-filter backdrop-blur-lg bg-opacity-20 lg:flex-row lg:justify-between">
      <h1
        className="cursor-pointer text-2xl tracking-widest font-bolder mt-10 font-bold lg:mt-0 mx-10"
        onClick={() => {
          handleScroll("main");
        }}
      >
        NIMALAN
      </h1>
      <ul className="w-full flex flex-row justify-around tracking-wider text-lg lg:w-2/5">
        <li
          className={`cursor-pointer ${
            currentNav === "about" ? "font-semibold" : null
          } `}
          onClick={() => {
            handleScroll("about");
          }}
        >
          about.
        </li>
        <li
          className={`cursor-pointer ${
            currentNav === "skills" ? "font-semibold" : null
          } `}
          onClick={() => {
            handleScroll("skills");
          }}
        >
          skills.
        </li>

        <li
          className={`cursor-pointer ${
            currentNav === "projects" ? "font-semibold" : null
          } `}
          onClick={() => {
            handleScroll("projects");
          }}
        >
          projects.
        </li>
        {/* <li className="cursor-pointer">blogs.</li> */}

        <li className="hidden lg:w-36 lg:flex items-center justify-around">
          <a href="https://github.com/nimalansivakumar" target="_blank">
            <FaGithub className="w-7 h-7 cursor-pointer" />
          </a>
          <a href="https://twitter.com/nimalancodes" target="_blank">
            <FaTwitter className="w-7 h-7 cursor-pointer" />
          </a>
          <a
            href="https://www.linkedin.com/in/nimalan-sivakumar-743507203/"
            target="_blank"
          >
            <FaLinkedin className="w-7 h-7 cursor-pointer" />
          </a>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
