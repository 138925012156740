import htmlLogo from "./assets/html.svg";
import cssLogo from "./assets/css.svg";
import javascriptLogo from "./assets/javascript.svg";
import tailwindLogo from "./assets/tailwind.svg";
import reactLogo from "./assets/reactjs.svg";
import nodeLogo from "./assets/nodejs.svg";
import expressLogo from "./assets/express.svg";
import firebaseLogo from "./assets/firebase.svg";
import mongoLogo from "./assets/mongodb.svg";
import gitLogo from "./assets/git.svg";
import githubLogo from "./assets/github.svg";
import mysql from "./assets/mysql.svg";
import nextjs from "./assets/nextjs.svg";
import java from "./assets/java.svg";
import figma from "./assets/figma.svg";

const Skills = () => {
  return (
    <div
      className="w-full h-screen flex flex-col justify-center items-center text-center"
      id="skills"
    >
      <div
        className="w-full h-auto flex flex-col justify-center items-center bg-clip-text text-transparent  lg:w-auto"
        style={{
          backgroundImage: "linear-gradient(90deg, #FFE53B 0%, #FF2525 74%)",
        }}
      >
        <h1 className="w-full h-20 text-5xl font-extrabold lg:h-32 lg:text-7xl">
          skills.
        </h1>
      </div>
      <div className="w-11/12 h-auto my-2 grid grid-cols-4 gap-10 justify-items-center items-center lg:grid-cols-6">
        <img src={htmlLogo} className="w-12 lg:w-16" />
        <img src={cssLogo} className="w-12 lg:w-16" />
        <img src={javascriptLogo} className="w-12 lg:w-16" />
        <img src={tailwindLogo} className="w-12 lg:w-16" />
        <img src={reactLogo} className="w-12 lg:w-16" />
        <img src={nextjs} className="w-12 lg:w-16" />
        <img src={java} className="w-12 lg:w-16" />
        <img src={nodeLogo} className="w-12 lg:w-16" />
        <img src={expressLogo} className="w-12 lg:w-16" />
        <img src={mongoLogo} className="w-12 lg:w-16" />
        <img src={firebaseLogo} className="w-12 lg:w-16" />
        <img src={mysql} className="w-12 lg:w-16" />
        <img src={gitLogo} className="w-12 lg:w-16" />
        <img src={githubLogo} className="w-12 lg:w-16" />
        <img src={figma} className="w-12 lg:w-16" />
      </div>
    </div>
  );
};

export default Skills;
