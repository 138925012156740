import About from "./About";
import Main from "./Main";
import Navbar from "./Navbar";
import Projects from "./Projects";
import Skills from "./Skills";
import ContactBar from "./ContactBar";
import { useState } from "react";

function App() {
  return (
    <div className="w-full h-screen font-inter text-black">
      <Navbar />
      <Main />
      <About />
      <Skills />
      <Projects />
      <ContactBar />
    </div>
  );
}

export default App;
