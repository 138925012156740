import ProfilePic from "./assets/profile-pic.png";

function About() {
  return (
    <div
      className="w-full h-screen flex flex-col justify-between items-center"
      id="about"
    >
      <div
        className="w-full h-full flex flex-col justify-center items-center bg-clip-text text-transparent"
        style={{
          backgroundImage:
            "linear-gradient(90deg, #FA8BFF 0%, #2BD2FF 52%, #2BFF88 90%)",
        }}
      >
        <h1 className="h-auto text-5xl font-extrabold lg:text-7xl">about.</h1>
        <p className="w-5/6 h-auto my-5 text-black text-xl text-center lg:text-4xl lg:leading-normal">
          I'm a final year <span className="font-bold">Computer Science </span>
          engineering student with a lot of passion towards building software
          products. I love minimal designs, blogging, branding, SaaS, coffee and
          music 🎧.
        </p>
        <div className="w-44 h-44">
          <img className="w-full h-full rounded-lg" src={ProfilePic} />
        </div>
      </div>
    </div>
  );
}

export default About;
